"use client";
import classNames from "classnames";
import { HTMLAttributes } from "react";
import { HiOutlineArrowSmallLeft } from "../../../react-icons/hi2";

interface Props extends HTMLAttributes<HTMLButtonElement> {
  cb?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  legend?: string;
  iconSize?: number;
  icon?: boolean;
}

export function BackButton({ cb, legend = "Retour", icon = true, iconSize = 45, ...props }: Props) {
  return (
    <button
      {...props}
      className={classNames("back-btn", cb ? "clickable" : "disabled", props.className)}
      onClick={cb ? (e) => cb(e) : () => { }}
      disabled={!cb}
    >
      {icon && (
        <div
          className="icon p-0"
          style={{
            width: iconSize + "px",
          }}
        >
          <HiOutlineArrowSmallLeft size={iconSize / 2} />
        </div>
      )}
      <p style={{ fontWeight: 600, fontSize: "1rem" }} className="mb-0">
        {legend}
      </p>
    </button>
  );
}
