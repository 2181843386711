import classNames from "classnames";
import React, { HTMLAttributes } from "react";

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: any | any[];
  position?: "left" | "center" | "right" | "auto";
  espacement?: number;
};


export default function BtnContainerMobile({ children, position = "auto", espacement = 7, className, ...props }: Props) {
  return (
    <div
      {...props}
      className={classNames(className, "btn-container")}
      style={{
        display: "flex",
        flexDirection: "column-reverse",
        gap: espacement + "px",
        marginLeft: position === "left" || position === "auto" ? 0 : "auto",
        marginRight: position === "right" || position === "auto" ? 0 : "auto",
        width: position === "auto" ? "100%" : "",
        ...props?.style
      }}
    >
      {children}
    </div>
  );
}
