type Props = {
  size?: number;
  color?: string;
};

export default function MyUnisoft({ size = 40, color }: Props) {
  const st0 = {
    fill: color ?? "#000000",
  };

  return (
    <svg
      className="icon"
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      width={size + "px"}
    >
      <path
        style={st0}
        id="Fill-1"
        d="M9.9,16.8v80.4c0,0.9,0.7,1.6,1.5,1.6H25c0.8,0,1.5-0.7,1.5-1.6V53.8l-16-37.1
	C10.4,16.4,9.9,16.5,9.9,16.8"
      />
      <path
        style={st0}
        id="Fill-3"
        d="M89.5,16.7l-16,37.1v43.5c0,0.9,0.7,1.6,1.5,1.6h13.5c0.8,0,1.5-0.7,1.5-1.6V16.9
	C90.1,16.5,89.7,16.4,89.5,16.7"
      />
      <path
        style={st0}
        id="Fill-6"
        d="M88.9,2.3C89.2,1.7,88.7,1,88.1,1H75c-1,0-1.8,0.6-2.2,1.5L50,55.7L27.1,2.5
	C26.8,1.6,25.9,1,24.9,1h-13c-0.7,0-1.1,0.7-0.8,1.3l24.6,57.5c3,7.1,5,14.5,5.8,22.2v15.5c0,0.9,0.7,1.6,1.5,1.6h0.5h13.1h0.5
	c0.8,0,1.5-0.7,1.5-1.6V82c0.8-7.6,2.8-15.1,5.8-22.2L88.9,2.3z"
      />
    </svg>
  );
}
