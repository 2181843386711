type Props = {
  size?: number;
  color?: string;
};

export default function Dext({ size = 40, color }: Props) {
  const cls = {
    fill: color ?? "#fc4c02",
  };

  return (
    <svg
      className="icon"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 297 208"
      width={size + "px"}
      color={color}
    >
      <g>
        <g id="Layer_1">
          <path
            style={cls}
            d="M224.4,110.6l16.4-57.4c.3-1.3-1-2.7-2.8-2.7h-29.5l-7,45.1-7.1-45.1h-29.7c-1.8,0-3.1,1.3-2.8,2.7l16.4,57.4-17.2,63.5c-.3,1.3,1,2.7,2.8,2.7h29.8l7.6-47.6,7.6,47.6h29.8c1.8,0,3.1-1.3,2.8-2.7l-17.2-63.5Z"
          />
          <path
            style={cls}
            d="M42.4,55.6c0-3.3-1.8-5.1-5.6-5.1h-4.5v105.6h4.3c3.8,0,5.8-1.7,5.8-5.1V55.6ZM39.7,30c22.2,0,35.3,9.1,35.3,27v93c0,8.8-3.1,15.3-9.4,19.9-6.3,4.6-14.9,6.8-25.9,6.8H2.9c-1.3.2-2.7-.8-2.8-2.2V32.2c.2-1.3,1.5-2.3,2.8-2.2h36.8Z"
          />
          <path
            style={cls}
            d="M116.3,72.1v22.5h9.6v-22.5c0-3.3-1.5-5.1-4.5-5.1-3.5.2-5.1,1.8-5.1,5.1ZM156.3,76.1v32.3c-.2,1.3-1.5,2.3-2.8,2.2h-37.1v43.9c0,3.3,1.8,5.1,5.3,5.1,2.2.3,4.1-1.2,4.5-3.3v-38.1h27.4c1.3-.2,2.7.8,2.8,2.2v34.6c.2,6.8-3.3,13.3-9.1,16.9-6.1,4.1-14.6,6.1-25.5,6.1-23.5,0-36.5-9.4-36.5-26.7v-71.8c0-20.6,12.1-30.2,36.3-30.2,23.2,0,34.8,8.8,34.8,26.7Z"
          />
          <path
            style={cls}
            d="M294,50.6h-13.6v-18.4c-.2-1.3-1.5-2.3-2.8-2.2h-23.4l-5.8,20.2v103.3c-.2,6.8,3.3,13.3,9.1,16.9,5.8,4,13.8,6,24,6.1h12.6c1.3.2,2.7-.8,2.8-2.2v-18.4h-10.6c-3.8,0-5.8-1.7-5.8-5.1v-79.9h16.4v-18.2c-.3-1.5-1.7-2.5-3-2.2h0Z"
          />
        </g>
      </g>
    </svg>
  );
}
