"use client";

import classNames from "classnames";
import { HTMLAttributes } from "react";
import { RoleIcon } from "..";
import { Shape } from "../../shapes";
import { Tooltip } from "../../infos";
import { ROLES } from "../../../../utilities/config/constants";
import { RoleTypeType } from "../../../../utilities/redux/types";

interface Props extends HTMLAttributes<HTMLDivElement> {
  roles: RoleTypeType[];
  size?: "small" | "normal" | "big";
  displayLegend?: boolean;
};

export function RoleIconContainer({ roles = [], size = "normal", displayLegend = true, ...props }: Props) {
  return (
    <div {...props} className={classNames(props.className, "roles-container")}>
      {roles?.map((role,) =>
        <>
          {displayLegend
            ? <div key={role} className={classNames("role", size)}>
              <Shape size={30}>
                <RoleIcon role={role} />
              </Shape>
              <span className="pr-1">
                {ROLES.find(constant => constant?.value === role).legend}
              </span>
            </div>
            : <Tooltip key={role} legend={ROLES.find(constant => constant?.value === role).legend}>
              <Shape size={30} className={classNames("role", size)}>
                <RoleIcon role={role} />
              </Shape>
            </Tooltip>
          }
        </>
      )}
    </div>
  );
}
