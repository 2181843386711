import classNames from "classnames";
import { CssVariablesType } from "../../styles/CSSVariablesTypes";

import type { JSX } from "react";

type Props = {
  children: JSX.Element | JSX.Element[];
  form?: "circle" | "square" | "rectangle";
  color?: CssVariablesType;

  paddingSize?: number;
  size?: number | undefined;
  className?: string;
  id?: string;
  style?: any;
  onClick?: any;
};

export function Shape({ form = "circle", className, id, children, color, size, style, paddingSize = size / 10 || 10, onClick }: Props) {
  return (
    <div
      id={id || null}
      onClick={() => {
        onClick && onClick();
      }}
      className={classNames("shape", form, className)}
      style={{
        background: color && `var(--color-${color})`,
        height: size ? size + "px" : "unset",
        width: size ? size + "px" : "fit-content",
        aspectRatio: form === "circle" || form === "square" ? "1/1" : "3/2",
        padding: paddingSize + "px",
        borderRadius: form === "circle" ? "50%" : form === "square" ? "20%" : "var(--size-borderRadius)",
        ...style,
      }}
    >
      {children}
    </div>
  );
}
