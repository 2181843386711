import classNames from "classnames";
import React, { HTMLAttributes } from "react";

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: any[] | any;
  position?: "left" | "center" | "right" | "auto";
  espacement?: number;
  forceChildrenLength?: number;
};

export default function BtnContainerDesktop({
  children,
  position = "auto",
  espacement = 7,
  forceChildrenLength,
  className,
  ...props
}: Props) {
  return (
    <div
      {...props}
      className={classNames(className, "btn-container")}
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(${forceChildrenLength
          ? forceChildrenLength + ", 1fr"
          : Array.isArray(children)
            ? children?.length + ", 1fr"
            : "1fr"
          })`,
        gap: espacement + "px",
        marginLeft: position === "left" || position === "auto" ? 0 : "auto",
        marginRight: position === "right" || position === "auto" ? 0 : "auto",
        width: position === "auto" ? "100%" : "",
        ...props?.style
      }}
    >
      {children}
    </div>
  );
}
