"use client";

import React, { HTMLAttributes, forwardRef } from "react";
// Utilities
import { useMediaQuery } from "../../../../utilities/hooks/useMediaQuery";

interface Props extends HTMLAttributes<HTMLDivElement> {
  columns?: number;
  space?: number;
  columnGap?: number;
  rowGap?: number;
  children: any | any[];
  alignItems?: "start" | "center" | "end" | "unset";
  justifyContent?: "flex-start" | "center" | "flex-end" | "unset" | "space-between";
  autoFit?: number;
  autoFill?: number;
  template?: string;
  mediaQuery?: number;
  overflow?: string;
  id?: string;
}

export const Grid = forwardRef(function Grid(
  {
    columns,
    space = 15,
    columnGap,
    rowGap,
    children,
    alignItems = "start",
    justifyContent = "unset",
    autoFit,
    autoFill,
    template,
    mediaQuery = 920,
    overflow = "unset",
    id,
    ...props
  }: Props,
  ref: any
) {
  const mediaQueryBoolean = useMediaQuery(mediaQuery);

  const defineTemplateColumns = () => {
    if (mediaQueryBoolean) {
      return { gridTemplateColumns: "1fr" };
    }
    if (columns && typeof columns === "number") {
      return { gridTemplateColumns: `repeat(${columns}, 1fr)` };
    }
    if (autoFit) {
      return {
        gridTemplateColumns: `repeat(auto-fit, minmax(${autoFit}px, 1fr))`,
      };
    }
    if (autoFill) {
      return {
        gridTemplateColumns: `repeat(auto-fill, minmax(${autoFill}px, 1fr))`,
      };
    }
    if (template) {
      return { gridTemplateColumns: template };
    }
  };

  return (
    <div
      ref={ref}
      {...props}
      id={id && id}
      className={props.className ? "grid " + props.className : "grid"}
      style={{
        display: "grid",
        ...defineTemplateColumns(),
        columnGap: columnGap ?? space + "px",
        rowGap: rowGap ?? space + "px",
        alignItems: alignItems,
        justifyContent,
        overflow: overflow,
        gridTemplateRows: "1fr",
        ...props.style,
      }}
    >
      {children}
    </div>
  );
});
